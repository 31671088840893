<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="flex justify-end">
          <div class="flex align-center">
            <router-link :to="{ name: 'studyPlan.calendar' }"
              ><md-icon>date_range</md-icon></router-link
            >
            <md-switch v-model="boolean" class="px-2 m-0"></md-switch>
            <md-icon>format_list_bulleted</md-icon>
          </div>
        </div>
        <div class="mb-4 mt-6 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <md-icon>record_voice_over</md-icon>
            <h3 class="pl-2 text-uppercase">Online Class</h3>
          </div>
          <div class="flex">
          <SwitchComponent :items="[{id:1, name: 'Daily'}, {id: 2, name: 'Weekly'}, {id: 3, name: 'Monthly'}]" />
            <md-button
              class="bg-victoria text-white font-bold outline-gray-400 rounded "
              @click="onCreateNew"
            >
              Create New
            </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3">
        <div class="mb-3 flex justify-between flex-wrap justify-sm-center justify-xs-center">
          <SelectComponent placeholder="Select one" class="w-64 m-2" />
          <SearchBox class="p-2 w-64 m-2" />
        </div>
        <md-divider></md-divider>
        <Table :items="onlineClasses" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-label="DATE">{{ item.date }}</md-table-cell>
            <md-table-cell md-label="TIME">{{ item.time }}</md-table-cell>
            <md-table-cell md-label="BRANCH">{{ item.branch }}</md-table-cell>
            <md-table-cell md-label="CLASS NAME">{{ item.className }}</md-table-cell>
            <md-table-cell md-label="MEDIUM">{{ item.medium }}</md-table-cell>
            <md-table-cell md-label="ZOOM LINK" class="text-victoria">{{ item.zoomLink }}</md-table-cell>
            <md-table-cell md-label="GROUP NAME">{{ item.groupName }}</md-table-cell>
            <md-table-cell md-label="COURSE NAME">{{ item.courseName }}</md-table-cell>
            <md-table-cell md-label="CREATED BY" class="text-victoria">{{ item.createdBy }}</md-table-cell>
            <span class="action">
              <md-icon
                class="bg-default rounded-full p-1 text-gray-700 text-base mr-1"
                @click.native="onShowDetails(item)"
              >visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base ">edit</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog >
      <!-- <TheOnlineClassCreate /> -->
      <components :content="content" :is="component"></components>
    </Dialog>
  </div>
</template>

<script>
import { Card, CardBody, SelectComponent, SwitchComponent, Dialog, Table } from "@/components";
import SearchBox from "@/components/atom/SearchBox";
import TheOnlineClassCreate from "@/components/molecule/online-class/TheOnlineClassCreate";
import ShowDetails from "@/components/molecule/online-class/ShowDetails";
import data from "@/data/online-class/data"
import { mapMutations } from "vuex";

export default {
  name: "Todo-List",
  components: {
    Card,
    Table,
    Dialog,
    CardBody,
    SearchBox,
    ShowDetails,
    SwitchComponent,
    SelectComponent,
    TheOnlineClassCreate
  },
  data() {
    return {
      boolean: true,
      content: {},
      component: 'TheOnlineClassCreate',
      onlineClasses: data.onlineClasses
    };
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onCreateNew() {
      this.component = 'TheOnlineClassCreate';
      this.dialog(true);
    },
    
    onShowDetails(item) {
      this.component = 'ShowDetails';
      this.content = item;
      this.dialog(true);
    },

  },
};
</script>